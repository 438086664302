import type { DashboardUser } from "@fonoa/data-access/auth0";
import { Logo } from "@fonoa/ui-components/icons";
import { GenericSidebar, SidebarLinkRoot } from "@fonoa/ui-components/sidebar";
import { A } from "@fonoa/ui-components/typography";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { useSidebarLinks } from "@/components/Sidebar/data";
import SidebarFooter from "@/components/Sidebar/SidebarFooter";
import { useDemoDataMode } from "@/hooks/useDemoDataMode";
import { useIsProductActiveCallback } from "@/hooks/useIsProductActive";
import { Products } from "@/lib/product";
import { routes } from "@/lib/routes";
import type { Tenant } from "@/server/trpc/routers/user/tenant";

import { SidebarDemoDataModeToggle } from "./SidebarDemoDataModeToggle";
import { SidebarResources } from "./SidebarResources";

interface SidebarProps {
  user?: DashboardUser;
  tenant?: Tenant;
}

export default function Sidebar({ user, tenant }: SidebarProps) {
  const router = useRouter();
  const { isDemoDataMode, toggleDemoMode, refreshDemoMode } = useDemoDataMode();
  const [footerOpen, setFooterOpen] = useState(false);
  const isActive = useIsProductActiveCallback();
  const sidebarLinks = useSidebarLinks();

  const currentProduct = Object.values(Products).find((product) =>
    router.pathname.includes(product)
  );

  const clientDemoMode = tenant?.demoMode ?? false;
  useEffect(() => {
    const userDemoMode = user?.demoMode ?? false;
    refreshDemoMode(clientDemoMode, userDemoMode);
  }, [user, tenant, refreshDemoMode, clientDemoMode]);

  const clientHasTenantIdDemo = tenant?.tenantIdDemo ? true : false;
  const isDemoDataModeToggleLocked = !clientHasTenantIdDemo;

  return (
    <GenericSidebar>
      <div className="flex h-full flex-col justify-between overflow-y-auto overflow-x-hidden">
        <div className="pt-8">
          <A href={routes.dashboard}>
            <div className="hidden dark:block">
              <Logo fill="white" width={90} spacing={{ mb: 12, ml: 6 }} />
            </div>
            <div className="dark:hidden">
              <Logo fillText="#08152D" width={90} spacing={{ mb: 12, ml: 6 }} />
            </div>
          </A>
          {sidebarLinks.map((link) => (
            <SidebarLinkRoot {...link} key={link.id} />
          ))}

          <SidebarDemoDataModeToggle
            isLocked={isDemoDataModeToggleLocked}
            isDemoDataMode={isDemoDataMode}
            toggleDemoDataMode={() => toggleDemoMode?.()}
          />
        </div>
        <SidebarResources
          currentProduct={currentProduct}
          isProductActive={isActive(currentProduct)}
        />
      </div>
      <div>
        <SidebarFooter
          firstname={user?.firstname || ""}
          lastname={user?.lastname || ""}
          company={tenant?.companyName || ""}
          open={footerOpen}
          onToggle={() => setFooterOpen(!footerOpen)}
        />
      </div>
    </GenericSidebar>
  );
}
